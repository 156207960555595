import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import {
  Checkbox,
  Snackbar,
  TextField,
  Button,
  Grid,
  Dialog,
  FormControlLabel,
  Slide,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Cancel } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import { green } from '@material-ui/core/colors'

import LoadGender from 'components/services/LoadGender'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function EditApplicant(props) {
  const classes = useStyles()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorServer, setErrorServer] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    email: props.id.email,
    document_id: props.id.documentId,
    name: props.id.name,
    lastName: props.id.lastName,
    gender: props.id.genderId,
    genderData:
      props.id.genderId !== null
        ? {
            id: props.id.genderId,
            name: props.id.gender.name,
          }
        : '',
    birthdate: props.id.birthdate,
    view_password: props.id.view_password === 1 ? true : false,
    errorEmail: false,
    errorDocument_id: false,
    errorLastName: false,
    errorName: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorEmail: false,
      errorDocument_id: false,
      errorLastName: false,
      errorName: false,
    })
    setOpenAlert(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setOpenAlert(false)
    setErrorServer(false)

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true })
      setMensaje('El campo "Nombre" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.lastName.length === 0) {
      setValues({ ...values, errorLastName: true })
      setMensaje('El campo "Apellidos" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } else if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument_id: true })
      setMensaje('El campo "Documento" es obligatorio.')
      setOpenAlert(true)
      setError('error')
    } /*else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true });
      setMensaje('El campo "Correo electrónico" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } */ else {
      const dataValue = {
        documentId: values.document_id,
        name: values.name,
        lastName: values.lastName,
        email: values.email !== '' ? values.email.toLowerCase().trim() : '',
        view_password: values.view_password === false ? 0 : 1,
      }

      if (values.birthdate !== null) {
        dataValue.birthdate = values.birthdate
      }

      if (values.gender !== null) {
        dataValue.genderId = values.gender
      }

      axios
        .put(`${urlServices}users/${props.id.id}`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setOpenAlert(true)
            setMensaje('Registro guardado con éxito.')
            setError('success')
            props.callBackRefresh()
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            setMensaje('Error de conexión, intente más tarde.')
            setOpenAlert(true)
            setError('error')
            setErrorServer(true)
          }
        })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleDateChange = (e) => {
    setValues({
      ...values,
      birthdate: e.target.value,
    })
  }

  const getResponseGender = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        gender: pro.id,
        genderData: pro,
      })
    } else {
      setValues({
        ...values,
        gender: '',
        genderData: pro,
      })
    }
  }

  const handleRadioChange = (e) => {
    setValues({
      ...values,
      view_password: e.target.checked,
    })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>
                      Editar candidato
                    </div>
                    <div className={classes.lineBottom}></div>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="name"
                      value={values.name}
                      onChange={handleChange('name')}
                      label="Nombre"
                      error={values.errorName}
                      className={classes.textField2}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange('lastName')}
                      label="Apellidos"
                      error={values.errorLastName}
                      className={classes.textField2}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="document_id"
                      value={values.document_id}
                      onChange={handleChange('document_id')}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          fontSize: 16,
                          marginTop: 25,
                        },
                      }}
                      label="Documento"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      required
                      helperText="Cédula, RUC o pasaporte."
                      error={values.errorDocument_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange('email')}
                      label="Correo electrónico"
                      error={values.errorEmail}
                      className={classes.textField2}
                      fullWidth
                      // required
                      // disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="date"
                      label="Fecha de nacimiento"
                      type="date"
                      value={values.birthdate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => handleDateChange(e)}
                      margin="dense"
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LoadGender
                      callback={getResponseGender}
                      value={values.genderData}
                      error=""
                    />
                  </Grid>
                  {props.id.profileId === 6 &&
                    props.id.account.activateViewPassword === 1 && (
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={values.view_password}
                              onClick={(event) => {
                                handleRadioChange(event)
                              }}
                              name="quiz"
                            />
                          }
                          label="Mostrar contraseña al inicio de sesión (El candidato podrá ver su contraseña en el inicio de sesión)"
                          style={{ color: '#000' }}
                        />
                      </Grid>
                    )}
                  <Grid item xs={12} sm={12}>
                    {errorServer && (
                      <Alert severity="error">
                        Error en la consulta al servidor
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === 'error' ? 'error' : 'success'}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
